
/* centre the contents of the card */
  #outerCard {
    display: inline-flex;
    flex-direction: column;
    height: 330px;
    width: 46vw;
    margin: 5px;
    border-radius: 22px;
    background: #feffff;
    box-shadow: inset 25px 25px 50px #e7e8e8, inset -25px -25px 50px #ffffff;
    position: relative;
    align-items: center;
  }

.card {
    padding: 0px 2vw 0px 2vw;
    position: absolute;
    top: 70px;
    font-size: 95%;
}
/* put the card buttons at the bottom of the card and next to each other */
#cardButtons {
    position: absolute;
    display: inline-flex;
    flex-direction: row;
    bottom: 0px;
}

/* style the agree button */
#agreeContainer {
    height: 70px;
    width: 23vw;
}

#agree {
    border: none;
    background-color: #007b60;
    font-weight: bold;
    text-decoration: none;
    font-size: 25px;
    border-bottom-left-radius: 22px;
    height: 70px;
    width: 23vw;
}

/* style the disagree button */
#disagreeContainer {
    height: 70px;
    width: 23vw;
}

#disagree {
    border: none;
    background-color: #ed364c;
    font-weight: bold;
    text-decoration: none;
    font-size: 25px;
    border-bottom-right-radius: 22px;
    height: 70px;
    width: 23vw;
}

/* style the card when it has been clicked agree */
#unclickAgree {
    height: 330px;
    width: 46vw;
    border-radius: 22px;
    background: #007b6057;
    position: relative;
    border: none;
    align-items: center;
    opacity: 0.8;
}

/* style the card when it has been clicked disagree */
#unclickDisagree {
    height: 330px;
    width: 46vw;
    border-radius: 22px;
    background: #ed364b3f;
    position: relative;
    border: none;
    align-items: center;
    opacity: 0.8;
}

/* change shape and size of cards for larger devices */
@media only screen and (min-width: 600px) {
    #outerCard {
        width: 23vw;
    }
    
    #agreeContainer {
      width: 11.5vw;
  }
  
  #agree {
      width: 11.5vw;
  }
  
  #disagreeContainer {
      width: 11.5vw;
  }
  
  #disagree {
      width: 11.5vw;
  }
  
  #unclickAgree {
      width: 23vw;
  }
  
  #unclickDisagree {
      width: 23vw;
  }

}

@media only screen and (min-width: 1200px) {
    #outerCard {
        width: 15vw;
    }
    
    #agreeContainer {
      width: 7.5vw;
  }
  
  #agree {
      width: 7.5vw;
  }
  
  #disagreeContainer {
      width: 7.5vw;
  }
  
  #disagree {
      width: 7.5vw;
  }
  
  #unclickAgree {
      width: 15vw;
  }
  
  #unclickDisagree {
      width: 15vw;
  }

}

@media only screen and (min-width: 1600px) {
    #outerCard {
        width: 10vw;
    }
    
    #agreeContainer {
      width: 5vw;
  }
  
  #agree {
      width: 5vw;
  }
  
  #disagreeContainer {
      width: 5vw;
  }
  
  #disagree {
      width: 5vw;
  }
  
  #unclickAgree {
      width: 10vw;
  }
  
  #unclickDisagree {
      width: 10vw;
  }

}