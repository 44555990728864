@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
  }

  /* body {
      display: flex;
  } */

.results {
    width: 90%;
    margin: auto;
}

#card {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 304px;
    width: 48vw;
    background: #feffff;
    box-shadow: inset 25px 25px 50px #e7e8e8, inset -25px -25px 50px #ffffff;
    align-content: center;
    justify-content: center;
    margin-top: 20px;
    border-radius: 22px;
    }

  .letters {
   height: 60px;
   line-height: 60px;
   width: 16vw;
   margin: 2px;
   display: inline-block;
   font-size: 28px;
   color: #feffff;
  }

#green {
    background-color: #007b60;
}

#blue {
    background-color: #4f91bd;
}

#orange {
    background-color: #ffbd4a;
}

#red {
      background-color: #ed364c;
}

#PTbutton {
    margin-top: 2vh;
    padding: 6px;
    border: 2px solid #007b60;
    background-color: #007b60;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 20px;
}

#PTanchor {
    text-decoration: none;
    color: white;
}

#greenParagraph {
    color: #007b60;
    margin-bottom: 5px;
}

#blueParagraph {
    color: #4f91bd;
    margin-bottom: 5px;
}

#orangeParagraph {
    color: #ffbd4a;
    margin-bottom: 5px;
}

#redParagraph {
    color: #ed364c;
    margin-bottom: 5px;
}

article {
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    margin: 1.5em 20px;
}

p {
    text-align: justify;
    margin-top: 5px;
}

.myStatements {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 5px;
    margin-top: 15px;
}

h2 {
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    font-weight: normal;
    font-family: 'Rubik', sans-serif;
}

h5 {
    font-weight: normal;
    font-family: 'Rubik', sans-serif;
}

h3 {
    font-weight: bold;
    font-family: 'Rubik', sans-serif;
}

ul li {
    font-weight: normal;
    font-family: 'Rubik', sans-serif;
}

#backButton {
    padding: 6px;
    border: 2px solid #007b60;
    color: white;
    background-color: #007b60;
    font-weight: bold;
    text-decoration: none;
}

#howToBtn {
    margin-bottom: 15px;
    color: #ed364c;
    text-decoration: underline; 
    cursor: pointer;
}

.btn {
    margin-top: 12px;
    margin-bottom: 5px;
    color: #ed364c;
    text-decoration: underline;
}

.statementList {
    text-align: justify;
    margin: 0px 7px;
}

.statementBox {
    border: solid;
    padding: 1em;
    margin-bottom: 1em;
    box-shadow: 5px 10px 8px #888888;
}


@media only screen and (min-width: 470px) {

    #card {
        height: 400px;
        width: 300px;
        }

        .letters {
            height: 100px;
            line-height: 100px;
            width: 100px;
            font-size: 35px;
           }

           #personalityType {
            position: initial;
            align-items: center;
            display: inline-flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
}

@media only screen and (min-width: 1100px) {
    #card {
        height: 450px;
        width: 300px;
        }

        .letters {
            height: 100px;
            line-height: 100px;
            width: 100px;
            font-size: 35px;
           }

           #leastLikeMe {
               width: 33vw;
            float: right;
            margin-right: 15px;
            width: 42%;
        }

        #mostLikeMe {
            width: 33vw;
            float: left;
            margin-left: 15px;
            width: 42%;
        }

        #personalityType {
            margin-top: 100px;
            align-items: center;
            display: inline-flex;
            flex-direction: column;
            flex-wrap: wrap;
            /* width: 600px; */
            margin-right: 60%;
        }
}

@media only screen and (min-width: 1600px) {
           #leastLikeMe {
            float: right;
            margin-right: 70px;
            width: 42%;
        }

        #mostLikeMe {
            float: left;
            margin-left: 70px;
            width: 42%;
        }

        #personalityType {
            margin-top: 100px;
            align-items: center;
            display: inline-flex;
            flex-direction: column;
            flex-wrap: wrap;
            width: 600px;
            margin-right: 50%;
        }

        /* #PTDiv {
            margin-top: 30em;
            width: 600px;
            margin-left: 300px;
        } */
}