.modal {
    position: fixed;
    left: 0vw;
    top: 20vh;
  }
  
  .modal_content {
    background-color: white;
    position: absolute;
    left: 5vw;
    width: 80vw;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid black;
  }
  
  .close {
    color: Black;
    float: right;
    margin-top: -15px;
    margin-right: -12px;
  }
  
  .close:hover {
    color: #ed364c;
    cursor: pointer;
  }