.scoreContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 15vh;
}

#score {
    position: fixed;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    background-color: black;
    color: white;
    width: 100%;
    padding: 10px 40px;
    opacity: 0.85;
}

#nextButton {
    display: flex;
    justify-content: center;
    background-color:black;
    color: white;
    border: 0in;
    width: 100%;
    height: 10vh;
    font-size: 7vh;
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    #score {
        font-size: 200%;
    }
}

@media only screen and (min-width: 1600px) {
    #score {
        font-size: 250%;
    }
}