@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;700&display=swap');

.container {
  max-width: 1100px;
}

#start {
    margin: auto;
    margin-top: 2vh;
  width: 92%;
  flex-direction: column;
  display: flex;
  height: auto;
  /* font-weight: normal; */
  font-family: 'Rubik', sans-serif;
}

#CSlogo {
    width: 10em;
    margin: auto;
    margin-top: 2vh;
  flex-direction: column;
  display: flex;
  height: auto;
}

#start h2 {
    font-weight: bold;
}

#start h3 {
    text-align: center;
    font-size: 120%;
    font-weight: normal;
}