* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

#cardSortHeader {
  text-align: center;
  color: white;
  background-color: #007b60;
  padding: 4vh 0vw;
  margin-bottom: 5px;
}

#basicInstruction {
  margin-bottom: 5vh;
}

@media only screen and (max-width: 600px) {
  #basicInstruction {
    padding: 2rem;
  }
}